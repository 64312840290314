<template>
  <img
    v-if="hasImage"
    :src="gatewayImage"
    :alt="gateway.code"
    class="gateway__name-image"
  >
  <i18n
    v-else-if="isManual"
    path="gateways.manualName"
    class="gateway__name--bold"
    tag="span"
  >
    <template #name>
      <span class="gateway__name--plain">
        {{ gateway.config.name }}
      </span>
    </template>
  </i18n>
  <span v-else class="gateway__name--bold">
    {{ $t('gateways.testGateway') }}
  </span>
</template>

<script>
const withImages = ['braintree', 'square', 'stripe', 'razorpay', 'paypal', 'omise', 'paystack'];

export default {
  name: 'GatewayName',
  props: {
    gateway: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gatewayImage() {
      return `/img/gateway-logos/${this.gateway.code}.svg`;
    },
    /**
     * Whether we have an image for this payment gateway
     *
     * @returns {boolean}
     */
    hasImage() {
      return withImages.includes(this.gateway.code);
    },
    isManual() {
      return this.gateway.code === 'manual';
    },
  },
};
</script>

<style lang="scss">
.gateway {
  &__name-image {
    @apply h-6;
  }

  &__name {
    &--bold {
      @apply font-bold;
    }

    &--plain {
      @apply font-normal;
    }
  }
}
</style>
