<template>
  <div class="gateways__square">
    <ChecAlert
      class="gateways-edit__help-notice"
      variant="info"
      icon="info"
      inline
      disable-close
    >
      <ChecIcon icon="info-square" />
      <i18n path="gateways.squareHelp" tag="span" />
    </ChecAlert>
  </div>
</template>

<script>
import { ChecAlert, ChecIcon } from '@chec/ui-library';

export default {
  name: 'Square',
  components: {
    ChecAlert,
    ChecIcon,
  },
};
</script>
