<template>
  <div class="gateways__omise">
    <ChecAlert
      class="gateways-edit__help-notice"
      variant="info"
      icon="info"
      inline
      disable-close
    >
      <ChecIcon icon="info-square" />
      <i18n path="gateways.omiseHelp" tag="span">
        <template #documentation>
          <a
            class="gateways-edit__info-link"
            href="https://www.omise.co/api-authentication"
            target="_blank"
          >
            {{ $t('gateways.omiseDocumentation') }}
          </a>
        </template>
      </i18n>
    </ChecAlert>
    <div class="input-wrapper">
      <TextField
        v-model="publicKey"
        required
        name="publicKey"
        :label="$t('gateways.publicKey')"
      />
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="secretKey"
        required
        :label="$t('gateways.secretKey')"
        name="secretKey"
      />
    </div>
  </div>
</template>

<script>
import { ChecAlert, ChecIcon, TextField } from '@chec/ui-library';

export default {
  name: 'Omise',
  components: {
    ChecAlert,
    ChecIcon,
    TextField,
  },
  model: {
    prop: 'config',
    event: 'input',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    publicKey: {
      get() {
        return this.config.omise_public_key || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, omise_public_key: value });
      },
    },
    secretKey: {
      get() {
        return this.config.omise_secret_key || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, omise_secret_key: value });
      },
    },
  },
};
</script>
