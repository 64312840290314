<template>
  <div class="gateways__stripe">
    <ChecAlert
      class="gateways-edit__help-notice"
      variant="info"
      icon="info"
      inline
      disable-close
    >
      <ChecIcon icon="info-square" />
      <i18n path="gateways.braintreeHelp" tag="span">
        <template #documentation>
          <a
            class="gateways-edit__info-link"
            href="https://developers.braintreepayments.com/guides/authorization/tokenization-key/javascript/v3"
            target="_blank"
          >
            {{ $t('gateways.braintreeDocumentation') }}
          </a>
        </template>
      </i18n>
    </ChecAlert>
    <div class="input-wrapper">
      <TextField
        v-model="merchantId"
        required
        name="merchantId"
        :label="$t('gateways.merchantId')"
      />
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="publicKey"
        required
        name="publicKey"
        :label="$t('gateways.publicKey')"
      />
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="privateKey"
        required
        :label="$t('gateways.privateKey')"
        name="privateKey"
      />
    </div>
  </div>
</template>

<script>
import { ChecAlert, ChecIcon, TextField } from '@chec/ui-library';

export default {
  name: 'Braintree',
  components: {
    ChecAlert,
    ChecIcon,
    TextField,
  },
  model: {
    prop: 'config',
    event: 'input',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    merchantId: {
      get() {
        return this.config.braintree_merchant_id || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, braintree_merchant_id: value });
      },
    },
    publicKey: {
      get() {
        return this.config.braintree_public_key || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, braintree_public_key: value });
      },
    },
    privateKey: {
      get() {
        return this.config.braintree_private_key || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, braintree_private_key: value });
      },
    },
  },
};
</script>
