var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gateways"},[_c('ChecHeader',{attrs:{"title":_vm.$t('settings.paymentGateways')}},[_c('ChecButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.toolTipHelper),expression:"toolTipHelper"}],attrs:{"variant":"round","color":"primary","icon":"plus","disabled":!_vm.canAdd},on:{"click":_vm.addGateway}},[_vm._v(" "+_vm._s(_vm.$t('general.add'))+" ")])],1),_c('DashboardTable',{staticClass:"gateways__table",attrs:{"align-right-after":4,"columns":[
      _vm.$t('gateways.gateway'),
      _vm.$t('general.id'),
      _vm.$t('general.environment'),
      _vm.$t('gateways.transactionVolume'),
      _vm.$t('general.actions') ],"state-key":"settings/gateways"}},_vm._l((_vm.gateways),function(gateway){return _c('TableRow',{key:gateway.id,attrs:{"id":gateway.id,"route-name":!_vm.nonEditableGateways.includes(gateway.code)
        ? 'settings.paymentGateways.edit'
        : null,"non-editable":_vm.nonEditableGateways.includes(gateway.code)}},[_c('td',[_c('GatewayName',{attrs:{"gateway":gateway}})],1),_c('td',[(gateway.id)?_c('div',{staticClass:"gateways__gateway-id-container"},[_c('CopyInline',{staticClass:"gateways__inline",attrs:{"copy-content":gateway.id || ''}},[_c('div',{staticClass:"gateways__gateway-id"},[_vm._v(" "+_vm._s(gateway.id)+" ")])])],1):_vm._e()]),_c('td',[_c('ChecDataPill',{attrs:{"color":gateway.sandbox.enabled ? 'orange' : 'green'}},[_vm._v(" "+_vm._s(gateway.sandbox.enabled ? _vm.$t('general.sandbox') : _vm.$t('general.live'))+" ")])],1),_c('td',{staticClass:"gateways__volume"},[_vm._v(" "+_vm._s(_vm.money(gateway.transaction_volume).full())+" ")]),_c('td',{staticClass:"gateways__options"},[(_vm.canAdd)?_c('ChecOptionsMenu',{staticClass:"dashboard-table__actions-button",attrs:{"menu-placement":"bottom-end"}},[(!_vm.nonEditableGateways.includes(gateway.code))?_c('ChecOption',{on:{"option-selected":function($event){return _vm.handleEdit(gateway.id)}}},[_vm._v(" "+_vm._s(_vm.$t('general.edit'))+" ")]):_vm._e(),_c('ChecOption',{attrs:{"destructive":""},on:{"option-selected":function($event){return _vm.handleDelete(gateway.id)}}},[_vm._v(" "+_vm._s(_vm.$t('general.delete'))+" ")])],1):_vm._e()],1)])}),1),_c('Pagination',{attrs:{"keep-page-on-mount":"","state-key":"settings/gateways"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }