<template>
  <div class="gateways__manual">
    <ChecAlert
      class="gateways-edit__help-notice"
      variant="info"
      icon="info"
      inline
      disable-close
    >
      <ChecIcon icon="info-square" />
      <i18n path="gateways.manualHelp" tag="span" />
    </ChecAlert>
    <div class="input-wrapper">
      <TextField
        v-model="name"
        required
        name="name"
        :label="$t('gateways.manualConfig.name')"
      />
    </div>
    <div class="input-wrapper">
      <ChecFormField
        :append-label="$t('general.whatIsThis')"
        :tooltip="$t('gateways.manualConfig.detailsHelp')"
      >
        <TextField
          v-model="details"
          name="details"
          :label="$t('general.details')"
        />
      </ChecFormField>
    </div>
    <div class="input-wrapper">
      <ChecFormField
        :append-label="$t('general.whatIsThis')"
        :tooltip="$t('gateways.manualConfig.paymentInstructionsHelp')"
      >
        <TextField
          v-model="paymentInstructions"
          name="paymentInstructions"
          :label="$t('gateways.manualConfig.paymentInstructions')"
        />
      </ChecFormField>
    </div>
  </div>
</template>

<script>
import {
  ChecAlert,
  ChecFormField,
  ChecIcon,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'Manual',
  components: {
    ChecAlert,
    ChecFormField,
    ChecIcon,
    TextField,
  },
  model: {
    prop: 'config',
    event: 'input',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.config.name || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, name: value });
      },
    },
    details: {
      get() {
        return this.config.details || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, details: value });
      },
    },
    paymentInstructions: {
      get() {
        return this.config.payment_instructions || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, payment_instructions: value });
      },
    },
  },
};
</script>
