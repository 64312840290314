<template>
  <div class="gateways__razorpay">
    <div class="input-wrapper">
      <TextField
        v-model="keyId"
        required
        name="keyId"
        :label="$t('gateways.keyId')"
      />
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="keySecret"
        required
        name="keySecret"
        :label="$t('gateways.keySecret')"
      />
    </div>
  </div>
</template>

<script>
import { TextField } from '@chec/ui-library';

export default {
  name: 'Razorpay',
  components: {
    TextField,
  },
  model: {
    prop: 'config',
    event: 'input',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    keyId: {
      get() {
        return this.config.key_id || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, key_id: value });
      },
    },
    keySecret: {
      get() {
        return this.config.key_secret || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, key_secret: value });
      },
    },
  },
};
</script>
