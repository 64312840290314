<template>
  <form class="gateway-settings" @submit.prevent>
    <GatewaysTable
      :can-add="canAdd"
      @add-gateway="handleAddGateway"
    />
    <GatewaysIntro
      v-if="showIntro"
      :can-add="canAdd"
      @add-gateway="handleAddGateway"
    />
    <router-view />
  </form>
</template>

<script>
import { mapState } from 'vuex';
import i18n from '@/lang';
import crud from '@/mixins/crud';
import onLoadToast from '@/mixins/onLoadToast';
import GatewaysIntro from '../components/gateways/GatewaysIntro.vue';
import GatewaysTable from '../components/gateways/GatewaysTable.vue';

export default {
  name: 'GatewaySettings',
  components: {
    GatewaysIntro,
    GatewaysTable,
  },
  mixins: [
    onLoadToast([{
      param: 'square-registered',
      message: i18n.t('gateways.saved'),
    }]),
    crud('settings/gateways', true),
  ],
  computed: {
    ...mapState('billing', ['details']),
    /**
     * Whether the merchant can add a live payment gateway
     *
     * @returns {Boolean}
     */
    canAdd() {
      if (!this.details) {
        return false;
      }
      if (this.details.plan.slug === 'developer') {
        return false;
      }
      return this.details.has_payment_method || false;
    },
    /**
     * Show the intro screen for gateways when either there are no gateways or there is
     * one but it's the test gateway. Don't show it when it's loading.
     *
     * @returns {boolean}
     */
    showIntro() {
      if (this.isLoading) {
        return false;
      }
      if (!this.gateways) {
        return false;
      }
      if (this.gateways.length === 0) {
        return true;
      }
      return this.gateways.length === 1 && this.gateways[0].code === 'test_gateway';
    },
  },
  mounted() {
    if (!this.details) {
      this.$store.dispatch('billing/FETCH_BILLING');
    }
    this.showOnLoadToast();
  },
  methods: {
    /**
     * Open add gateway panel
     */
    handleAddGateway() {
      this.$router.push({ name: 'settings.paymentGateways.add' });
    },
  },
};
</script>
