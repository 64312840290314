<template>
  <ChecSlideoutPanel
    :title="panelTitle"
    close-on-overlay-click
    @close="handleClose"
  >
    <ChecLoading v-if="isLoading" />
    <!-- Checking gateway.code prevents stale state from hitting "Back" in browser -->
    <ChecLoading
      v-if="isRedirectingToSquare && gateway.code"
      :message="$t('gateways.redirectingToSquare')"
    />
    <div class="gateways-edit__selector input-wrapper__row space-x-4">
      <div v-if="isAdd" class="w-10/12">
        <ChecDropdown
          v-model="gateway.code"
          required
          :label="$t('general.paymentGateway')"
          name="code"
          :options="gatewayOptions"
        />
      </div>
      <div v-else class="w-10/12">
        <TextField
          :value="gatewayLabels[gateway.code]"
          :label="$t('general.paymentGateway')"
          variant="disabled"
        />
      </div>
      <div class="gateways-edit__sandbox-toggle w-2/12">
        <ChecSwitch
          v-model="gateway.sandbox"
          v-tooltip="sandboxTooltip"
          :disabled="!supportsSandbox || onlySandbox"
          name="sandbox"
          required
          prefix-label
        >
          {{ $t('general.sandboxMode') }}
        </ChecSwitch>
      </div>
    </div>
    <!-- Individual gateway components for config fields -->
    <Stripe
      v-if="gateway.code === 'stripe'"
      v-model="config"
    />
    <Square
      v-if="gateway.code === 'square'"
    />
    <Braintree
      v-if="gateway.code === 'braintree'"
      v-model="config"
    />
    <PayPal
      v-if="gateway.code === 'paypal'"
      v-model="config"
    />
    <Razorpay
      v-if="gateway.code === 'razorpay'"
      v-model="config"
    />
    <Manual
      v-if="gateway.code === 'manual'"
      v-model="config"
    />
    <Omise
      v-if="gateway.code === 'omise'"
      v-model="config"
    />
    <Paystack
      v-if="gateway.code === 'paystack'"
      v-model="config"
    />
    <template #toolbar>
      <ChecButton
        v-if="!isAdd"
        outline
        color="red"
        icon="trash"
        @click="handleDelete(gatewayId)"
      >
        {{ $t('general.delete' ) }}
      </ChecButton>
      <div class="gateways-edit__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="handleClose">
          {{ $t('general.cancel' ) }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          color="primary"
          :disabled="isLoading"
          @click="handleSave"
        >
          {{ $t('general.saveChanges') }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecDropdown,
  TextField,
  ChecLoading,
  ChecSlideoutPanel,
  ChecSwitch,
} from '@chec/ui-library';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import crud from '@/mixins/crud';
import resolveDomain from '@/lib/resolveDomain';
import Stripe from './stripe.vue';
import Square from './square.vue';
import Braintree from './braintree.vue';
import PayPal from './paypal.vue';
import Razorpay from './razorpay.vue';
import Manual from './manual.vue';
import Omise from './omise.vue';
import Paystack from './paystack.vue';

export default {
  name: 'AddEditGateway',
  components: {
    ChecButton,
    ChecDropdown,
    TextField,
    ChecLoading,
    ChecSlideoutPanel,
    ChecSwitch,
    Stripe,
    Square,
    Braintree,
    PayPal,
    Razorpay,
    Manual,
    Omise,
    Paystack,
  },
  mixins: [addNotification, confirm, crud('settings/gateways')],
  data() {
    const gatewayLabels = {
      stripe: this.$t('gateways.stripe'),
      square: this.$t('gateways.square'),
      braintree: this.$t('gateways.braintree'),
      paypal: this.$t('gateways.paypal'),
      razorpay: this.$t('gateways.razorpay'),
      manual: this.$t('gateways.manual'),
      test_gateway: this.$t('gateways.testGateway'),
      omise: this.$t('gateways.omise'),
      paystack: this.$t('gateways.paystack'),
    };
    return {
      gateway: {
        code: '',
        sandbox: false,
      },
      isRedirectingToSquare: false,
      config: {},
      gatewayLabels,
      gatewayOptions: [
        { value: 'stripe', label: this.$t('gateways.stripe'), sandbox: true },
        { value: 'square', label: this.$t('gateways.square') },
        { value: 'braintree', label: this.$t('gateways.braintree'), sandbox: true },
        { value: 'paypal', label: this.$t('gateways.paypal') },
        { value: 'razorpay', label: this.$t('gateways.razorpay') },
        { value: 'manual', label: this.$t('gateways.manual') },
        { value: 'omise', label: this.$t('gateways.omise'), sandbox: true },
        { value: 'paystack', label: this.$t('gateways.paystack'), sandbox: true },
        {
          value: 'test_gateway',
          label: this.$t('gateways.testGateway'),
          sandbox: true,
          onlySandbox: true,
        },
      ],
      nonEditableGateways: ['square', 'test_gateway'],
    };
  },
  computed: {
    /**
     * Whether this panel is in "add mode" and a gateway is being added
     */
    isAdd() {
      return this.$route.name === 'settings.paymentGateways.add';
    },
    gatewayId() {
      return this.$route.params.id;
    },
    sandboxTooltip() {
      if (this.supportsSandbox || !this.gateway.code) {
        return null;
      }
      return this.$t('gateways.sandboxNotAvailable');
    },
    /**
     * Whether the chosen gateway supports sandbox mode
     *
     * @returns {Boolean}
     */
    supportsSandbox() {
      return this.gatewayOptions
        .find((option) => option.value === this.gateway.code)
        ?.sandbox ?? false;
    },
    /**
     * Whether the chosen gateway only supports sandbox mode
     *
     * @returns {Boolean}
     */
    onlySandbox() {
      return this.gatewayOptions
        .find((option) => option.value === this.gateway.code)
        ?.onlySandbox ?? false;
    },
    panelTitle() {
      if (this.isAdd) {
        return this.$t('gateways.add');
      }

      return this.$t('gateways.edit', { gateway: this.gatewayLabels[this.gateway.code] });
    },
  },
  watch: {
    /**
     * When the gateway type is changed, this code will pre-fill the sandbox switch
     * in a couple of different scenarios.
     */
    'gateway.code': function codeWatcher() {
      if (this.onlySandbox) {
        this.gateway.sandbox = true;
        return;
      }

      if (!this.supportsSandbox) {
        this.gateway.sandbox = false;
      }
    },
  },
  async mounted() {
    if (this.isAdd) {
      return;
    }

    // The route specifies a gateway which we'll load (if not already) and then "get" from VueX
    const { id } = this.$route.params;
    const gateway = await this.load(id).then(() => this.get(id));

    // Prevent non-editable gateways from being force-edited
    if (this.nonEditableGateways.includes(gateway.code)) {
      this.$router.push({ name: 'settings.paymentGateways' });
      return;
    }

    // Hydrate the relevant data from the saved gateway.
    this.gateway = { code: gateway.code, sandbox: gateway.sandbox.enabled };
    this.config = { ...gateway.config };
  },
  methods: {
    handleClose() {
      this.$router.push({ name: 'settings.paymentGateways' });
    },
    handleSave() {
      const payload = {
        ...this.gateway,
        return_to: this.gateway.code === 'square'
          ? `${resolveDomain('dashboard')}/settings/gateways?m=square-registered`
          : undefined,
        config: this.config,
      };

      // We are not being optimistic because:
      // * we need to show an ID in the table, which is API generated
      // * it would be strange for Square, which has an external redirect
      // Anyone who wants to is welcome to adjust the flow/designs so it can be
      // optimistic.
      (
        this.isAdd
          ? this.create(payload, true, false)
          : this.update(this.$route.params.id, payload, true, false)
      )
        .then((response) => {
          // Handle external OAuth redirects
          if (response?.registration_url) {
            this.isRedirectingToSquare = true;
            window.setTimeout(() => {
              window.location = response.registration_url;
            }, 1000); // Give the user a second or so to read the loading message
            return;
          }
          // Standard gateways
          this.handleClose();
          this.addNotification(this.$t('gateways.saved'));

          if (this.isAdd) {
            // Reload the merchant, statistics probably changed
            this.$store.dispatch('merchant/FETCH_MERCHANT', {
              showLoading: false,
              force: true,
            }, { root: true });
          }
        })
        .catch(() => {
          this.addNotification(this.$t('gateways.saveFailed'), 'error');
        });
    },
    /**
     * Confirm and delete payment gateway.
     *
     * @param {string} id
     */
    async handleDelete(id) {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('gateways.confirmDelete'),
      )) {
        return;
      }
      // Optimistically close panel
      this.handleClose();

      this.delete(id)
        .then(() => {
          this.addNotification(this.$t('gateways.deleted'));

          // Reload the merchant, statistics might've changed
          this.$store.dispatch('merchant/FETCH_MERCHANT', {
            showLoading: false,
            force: true,
          }, { root: true });
        })
        .catch((error) => {
          this.addNotification(this.$t('gateways.deleteFailed'), 'error');
          this.$router.push({ name: 'settings.paymentGateways.edit', params: { id } });
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.input-wrapper {
  &--centered {
    @apply flex;
  }
}

.gateways-edit {
  &__toolbar {
    // todo should these be in the ChecSlideoutPanel styles in UI library?
    @apply flex justify-end w-full;
  }

  &__help-notice {
    @apply my-2;
  }

  &__info-link {
    @apply font-bold;
  }

  &__sandbox-toggle {
    @apply flex justify-end;
  }

  &__selector {
    @apply mb-2;
  }
}
</style>
