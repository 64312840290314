import actions from '@/store/actions';

/**
 * Creates a mixin that add a method that displays a toast based on a parameter in the request. The
 * query parameter can be provided, but defaults to `m` - eg. `?m=registered`
 *
 * The method added is `showOnLoadToast`.
 *
 * Supported messages are provided as an array of objects. Messages have the keys:
 * - `message` The message to show
 * - `param` The param that will be
 * - `type` The type of toast to show. Defaults to "success"
 *
 * @param {Array<Object>} messages
 * @param {String} queryParam
 */
export default function onLoadToastMixin(messages, queryParam = 'm') {
  return {
    methods: {
      showOnLoadToast() {
        const requestedMessage = this.$route.query[queryParam];
        const messagesByParam = messages.reduce(
          (acc, message) => ({ ...acc, [message.param]: message }),
          {},
        );

        if (!requestedMessage || !Object.hasOwnProperty.call(messagesByParam, requestedMessage)) {
          return;
        }

        const { message, type } = messagesByParam[requestedMessage];

        this.$store.dispatch(`notifications/${actions.ADD_NOTIFICATION}`, {
          type,
          text: message,
        });
      },
    },
  };
}
