<template>
  <div class="gateways__stripe">
    <ChecAlert
      class="gateways-edit__help-notice"
      variant="info"
      icon="info"
      inline
      disable-close
    >
      <ChecIcon icon="info-square" />
      <i18n path="gateways.stripeHelp" tag="span">
        <template #documentation>
          <a class="gateways-edit__info-link" href="https://stripe.com/docs/keys" target="_blank">
            {{ $t('gateways.stripeDocumentation') }}
          </a>
        </template>
      </i18n>
    </ChecAlert>
    <div class="input-wrapper">
      <TextField
        v-model="publishableKey"
        required
        name="publishableKey"
        :label="$t('gateways.publishableKey')"
      />
    </div>
    <div class="input-wrapper">
      <TextField
        v-model="secretKey"
        required
        :label="$t('gateways.secretKey')"
        name="secretKey"
      />
    </div>
    <div class="input-wrapper">
      <ChecFormField
        class="w-full"
        :append-label="$t('general.learnMoreButton')"
        :tooltip="$t('gateways.stripeMethodTypesTooltip')"
        @action-click="seePaymentIntentDocs"
      >
        <ChecDropdown
          v-model="paymentMethod"
          placeholder="Select option"
          :label="$t('gateways.stripeMethodTypesLabel')"
          :options="paymentMethodOptions"
          multiselect
          chec-option-class="text-left"
          name="paymentMethod"
        />
      </ChecFormField>
    </div>
  </div>
</template>

<script>
import {
  ChecAlert,
  ChecDropdown,
  ChecFormField,
  ChecIcon,
  TextField,
} from '@chec/ui-library';

export default {
  name: 'Stripe',
  components: {
    ChecAlert,
    ChecDropdown,
    ChecFormField,
    ChecIcon,
    TextField,
  },
  model: {
    prop: 'config',
    event: 'input',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paymentMethods: [
        'alipay',
        'au_becs_debit',
        'bancontact',
        'card',
        'card_present',
        'eps',
        'giropay',
        'ideal',
        'klarna',
        'p24',
        'sepa_debit',
        'sofort',
      ],
    };
  },
  computed: {
    publishableKey: {
      get() {
        return this.config.stripe_publishable_key || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, stripe_publishable_key: value });
      },
    },
    secretKey: {
      get() {
        return this.config.stripe_secret_key || '';
      },
      set(value) {
        this.$emit('input', {
          ...this.config,
          stripe_secret_key: value,
          access_token: value, // todo remove this one day
        });
      },
    },
    paymentMethod: {
      get() {
        return this.config.payment_method_types || ['card'];
      },
      set(value) {
        this.$emit('input', { ...this.config, payment_method_types: value });
      },
    },
    paymentMethodOptions() {
      return this.paymentMethods.map((method) => ({
        value: method,
        label: method,
      }));
    },
  },
  methods: {
    seePaymentIntentDocs() {
      window.open('https://stripe.com/docs/api/payment_intents/create#create_payment_intent-payment_method_types');
    },
  },
};
</script>
