<template>
  <div class="gateways__intro bg-confetti">
    <ChecCard compact class="gateways__intro-card">
      <h3 class="gateways__intro-header">
        {{ $t('gateways.introTitle') }}
      </h3>
      <p>{{ $t('gateways.introDescription') }}</p>
      <ChecDivider class="gateways__intro-divider">
        {{ $t('gateways.weSupport') }}
      </ChecDivider>
      <div class="gateways__intro-logos">
        <img
          v-for="gateway in gateways"
          :key="gateway"
          class="gateways__intro-logo"
          :src="`/img/gateway-logos/${gateway}.svg`"
          :alt="gateway"
        >
      </div>
      <ChecAlert
        v-if="!canAdd"
        variant="warning"
        inline
        disable-close
        class="gateways__alert-message"
      >
        <span>
          {{ $t('gateways.paymentMethodHelper') }}
        </span>
        <ChecButton
          color="red"
          variant="round"
          class="gateways__alert-button"
          tag-type="route"
          :to="{ name: 'settings.billing.home' }"
        >
          {{ $t('billing.addPaymentMethod') }}
        </ChecButton>
      </ChecAlert>
      <div v-else class="gateways__intro-actions">
        <ChecButton
          :disabled="!canAdd"
          variant="round"
          color="primary"
          icon="plus"
          @click="addGateway"
        >
          {{ $t('gateways.add') }}
        </ChecButton>
      </div>
    </ChecCard>
  </div>
</template>

<script>
import {
  ChecAlert,
  ChecButton,
  ChecCard,
  ChecDivider,
} from '@chec/ui-library';

export default {
  name: 'GatewaysIntro',
  components: {
    ChecAlert,
    ChecButton,
    ChecCard,
    ChecDivider,
  },
  props: {
    canAdd: Boolean,
  },
  computed: {
    /**
     * The supported payment gateways (that have a logo)
     *
     * @returns {string[]}
     */
    gateways() {
      return ['stripe', 'braintree', 'razorpay', 'paypal', 'square', 'omise', 'paystack'];
    },
  },
  methods: {
    /**
     * Emits button click to open add gateway panel
     */
    addGateway() {
      this.$emit('add-gateway');
    },
  },
};
</script>

<style lang="scss">
.gateways {
  &__billing-link {
    @apply font-bold;
  }

  &__intro {
    @apply pt-24 pb-40 mt-4 whitespace-pre-line bg-cover;
  }

  &__intro-card {
    @apply mx-auto max-w-xl;

    &.card--compact,
    &.card--compact .card__inner-wrapper {
      // Overriding default card styles for some reason (as per designs)
      @apply rounded-lg;
    }
  }

  &__intro-divider {
    @apply my-4;
  }

  &__intro-link {
    @apply font-bold;
  }

  &__intro-header {
    @apply mb-4 font-bold;
  }

  &__intro-logos {
    @apply grid grid-cols-6 gap-4 mx-16 items-center justify-center;
  }

  &__intro-logo {
    @apply h-6 col-span-2 mx-auto;

    /* Center two orphan logos */

    &:last-child:nth-child(3n - 1) {
      grid-column-end: -2;
    }

    &:nth-last-child(2):nth-child(3n + 1) {
      @apply col-end-4;
    }

    /* Center single orphan logo */

    &:last-child:nth-child(3n - 2) {
      @apply col-end-5;
    }
  }

  &__intro-actions {
    @apply flex justify-end mt-8;
  }

  &__alert-message {
    @apply mt-8;

    // Ensure the button is placed below the text by switching the flex direction set in the UI lib.
    .alert__text {
      @apply flex-col;
    }
  }

  &__alert-button {
    @apply mt-2;
  }
}
</style>
