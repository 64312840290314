<template>
  <div class="gateways">
    <ChecHeader :title="$t('settings.paymentGateways')">
      <ChecButton
        v-tooltip="toolTipHelper"
        variant="round"
        color="primary"
        icon="plus"
        :disabled="!canAdd"
        @click="addGateway"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </ChecHeader>
    <DashboardTable
      class="gateways__table"
      :align-right-after="4"
      :columns="[
        $t('gateways.gateway'),
        $t('general.id'),
        $t('general.environment'),
        $t('gateways.transactionVolume'),
        $t('general.actions'),
      ]"
      state-key="settings/gateways"
    >
      <TableRow
        v-for="gateway in gateways"
        :id="gateway.id"
        :key="gateway.id"
        :route-name="!nonEditableGateways.includes(gateway.code)
          ? 'settings.paymentGateways.edit'
          : null"
        :non-editable="nonEditableGateways.includes(gateway.code)"
      >
        <td>
          <GatewayName :gateway="gateway" />
        </td>
        <td>
          <div v-if="gateway.id" class="gateways__gateway-id-container">
            <CopyInline
              class="gateways__inline"
              :copy-content="gateway.id || ''"
            >
              <div
                class="gateways__gateway-id"
              >
                {{ gateway.id }}
              </div>
            </CopyInline>
          </div>
        </td>
        <td>
          <ChecDataPill :color="gateway.sandbox.enabled ? 'orange' : 'green'">
            {{ gateway.sandbox.enabled ? $t('general.sandbox') : $t('general.live') }}
          </ChecDataPill>
        </td>
        <td class="gateways__volume">
          {{ money(gateway.transaction_volume).full() }}
        </td>
        <td class="gateways__options">
          <ChecOptionsMenu
            v-if="canAdd"
            class="dashboard-table__actions-button"
            menu-placement="bottom-end"
          >
            <ChecOption
              v-if="!nonEditableGateways.includes(gateway.code)"
              @option-selected="handleEdit(gateway.id)"
            >
              {{ $t('general.edit') }}
            </ChecOption>
            <ChecOption
              destructive
              @option-selected="handleDelete(gateway.id)"
            >
              {{ $t('general.delete') }}
            </ChecOption>
          </ChecOptionsMenu>
        </td>
      </TableRow>
    </DashboardTable>
    <Pagination
      keep-page-on-mount
      state-key="settings/gateways"
    />
  </div>
</template>

<script>
import {
  ChecButton,
  ChecDataPill,
  ChecHeader,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import Pagination from '@/components/Pagination.vue';
import CopyInline from '@/components/CopyInline.vue';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import addNotification from '@/mixins/addNotification';
import money from '@/lib/helpers/money';
import DashboardTable from '@/components/DashboardTable.vue';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import GatewayName from './GatewayName.vue';

export default {
  name: 'GatewaysTable',
  components: {
    TableRow,
    DashboardTable,
    ChecButton,
    ChecDataPill,
    ChecHeader,
    ChecOption,
    ChecOptionsMenu,
    CopyInline,
    GatewayName,
    Pagination,
  },
  mixins: [
    crud('settings/gateways', true),
    addNotification,
    confirm,
  ],
  props: {
    canAdd: Boolean,
  },
  data() {
    return {
      nonEditableGateways: ['square', 'test_gateway'],
    };
  },
  computed: {
    /**
     * Returns tooltip helper if 'Add' button is disabled
     *
     * @returns {string|null}
     */
    toolTipHelper() {
      if (!this.canAdd) {
        return this.$t('gateways.paymentMethodHelper');
      }
      return null;
    },
  },
  methods: {
    money,
    /**
     * Emits button click to open add gateway panel
     */
    addGateway() {
      this.$emit('add-gateway');
    },
    handleEdit(id) {
      this.$router.push({ name: 'settings.paymentGateways.edit', params: { id } });
    },
    /**
     * Confirm and delete payment gateway.
     *
     * @param {string} id
     */
    async handleDelete(id) {
      if (!await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('gateways.confirmDelete'),
      )) {
        return;
      }

      this.delete(id)
        .then(() => {
          this.addNotification(this.$t('gateways.deleted'));

          // Reload the merchant, statistics might've changed
          this.$store.dispatch('merchant/FETCH_MERCHANT', {
            showLoading: false,
            force: true,
          }, { root: true });
        })
        .catch((error) => {
          this.addNotification(this.$t('gateways.deleteFailed'), 'error');
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.gateways {
  code {
    @apply py-1 px-2 rounded-sm text-xs font-mono tracking-wide bg-gray-200;
  }

  &__volume {
    @apply text-green-600;
  }

  &__gateway-id-container {
    @apply inline-block items-center border border-gray-300 p-2 bg-gray-100 rounded;
  }

  &__gateway-id {
    @apply font-mono text-xs select-all break-all;
  }

  &__table {
    @apply mt-8;
  }
}
</style>
