<template>
  <div class="gateways__paypal">
    <div class="input-wrapper">
      <TextField
        v-model="email"
        required
        name="email"
        :label="$t('gateways.email')"
      />
    </div>
  </div>
</template>

<script>
import { TextField } from '@chec/ui-library';

export default {
  name: 'PayPal',
  components: {
    TextField,
  },
  model: {
    prop: 'config',
    event: 'input',
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  computed: {
    email: {
      get() {
        return this.config.email || '';
      },
      set(value) {
        this.$emit('input', { ...this.config, email: value });
      },
    },
  },
};
</script>
